import * as React from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TablePagination,
    TableRow,
    Checkbox,
    Tooltip,
    IconButton,
    Skeleton,
    Pagination
} from '@material-ui/core';

import {
    CircleOutlined as CircleOutlinedIcon,
    CheckCircle as CheckCircleIcon,
    Close as CloseIcon
} from '@mui/icons-material';

import {
    Grid,
    Typography
} from '@material-ui/core';

import { Steps } from 'intro.js-react';

import Alert from '../Alert';

import SelectTableHeader from './SelectTableHeader';
import SelectTableToolbar from './SelectTableToolbar';
import FormGroup from '../FormGroup';
import { useNavigate, useSearchParams } from 'react-router-dom';

const tableCellStyle = {
    borderBottom: 'none',
    borderRight: '1px solid',
    borderRightColor: 'background.default'
};

/**
 * Renders a table with the abality to select rows, filtering, pagination, sorting, ...
 * 
 * @param {Boolean} loading Is data behing fetched from the API?
 * @param {Object} data An API object 
 *          {count:, itemsPerPage:, currentPage:, next:, previous:, results: []}
 * @param {Object[]} cols See SelectTableHeader
 * @param {method} getRow Given a data.results item, returns an aray of Components to render the row (one per column)
 * @param {String} defaultOrder 
 * @param {String} defaultOrderBy
 * @param {Object} params Dictionary of parameters key:val
 * @param {method} addParam Receives object with {id1: val1, id2: val2, ...}) to update field values
 * @param {method} updateParams Receives (to_clean, id, val), deleting the values of fields in to_clean and updates [id] field value
 * @param {Object[]} filters See components.ChaveLusa.FormGroup
 *                      - When defined, a button to open filters will be shown and will allow to toggle its display
 *                      - When null, instead of the button a loading gif will happear (to be used when filters are loading...)
 *                      - When undefined, nothing is shown (to be used where there are no filters)
 * @param {Object[]} docsExport See SelectTableToolbar
 * @param {String} addNew Method called when user clicks on button to add new entity to table. Add button is not displayed if null.
 * @param {Object} tutorial Object with tutorial data, according to https://www.npmjs.com/package/intro.js-react structure
 * @param {String} entityNameSingular Name of entities behing displayed (singular name)
 * @param {Method} goBack If defined, ago back arrow btn is displayed next to the title, that when clicked triggers this method.
 * @param {Boolean} allowSelect If false, it is not possible to select table rows
 * 
 * Callbacks
 * @param {Method} (Optional) onSubmit If defined, when items selcted a button "Add" is displayed and when clicked this callback is triggered, given as parameter the ids of the items selected (array)
 */
export default function SelectTable({
    loading, data, cols, getRow, defaultOrder, defaultOrderBy, params, addParam, updateParams, filters, addNew, tutorial, entityNameSingular, goBack,
    // Bulk operations 
    allowSelect, docsExport, multipleSelectionOps,
    // Callbacks
    onSubmit,
    // Behaviour manipulation
    allowSearch
}) {

    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    // Op param trigger 
    React.useEffect(() => {
        // Example usage: ?filter=pending_messages:true
        if (searchParams.get("filter")!==null && filters) {
            if (searchParams.get("filter").split(":").length!==2) return;
            let id = searchParams.get("filter").split(":")[0];
            let val = searchParams.get("filter").split(":")[1];
            if (!filters.some(f => f.id===id)) return;
            updateParams([], id, val);
            setShowFilters(true);
            navigate("?"); // Reset params
        }
    }, [filters, searchParams])

    // SORTING
    const [order, setOrder] = React.useState(defaultOrder);
    const [orderBy, setOrderBy] = React.useState(defaultOrderBy);
    const isAsc = order === 'asc';

    const handleRequestSort = (property) => {
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    React.useEffect(() => {
        addParam({'ordering': isAsc ? orderBy : '-' + orderBy});
    }, [order, orderBy]);

    // SELECTION
    const [selected, setSelected] = React.useState([]);
    const [selectedFromPrevPage, setSelectedFromPrevPage] = React.useState([]);

    const handleSelectAllClick = (event) => {
        if (data != null) {
            if (event.target.checked) {
                const newSelecteds = data.results.map((n) => n.id);
                setSelected(oldSelection => [...new Set([...oldSelection, ...newSelecteds])]);
                return;
            }
        }
        setSelected(selected.filter(s => !!selectedFromPrevPage && selectedFromPrevPage.includes(s)));
    };

    const handleClick = (event, id) => {
        let newSelected = [...selected];
        if (!selected.includes(id)) {
            // If does not exist, add to selected
            newSelected.push(id);
        } else {
            // Else, remove
            newSelected.splice(newSelected.indexOf(id), 1);
        }
        setSelected(newSelected);
    };

    React.useEffect(() => {
        if (!data) return;
        // When data changes, update selection from previous pages
        let currentPageIds = data.results.map(r => r.id);
        setSelectedFromPrevPage(selected.filter(id => !currentPageIds.includes(id)));
    }, [data]);

    const removeSelectedFromPrevPage = () => {
        setSelected(oldS => oldS.filter(id => !selectedFromPrevPage.includes(id)));
        setSelectedFromPrevPage([]);
    }

    const isSelected = (id) => selected.indexOf(id) !== -1;

    // TEXT SEARCH
    const [searchField, setSearchField] = React.useState('');

    React.useEffect(() => {
        addParam({'search': searchField});
    }, [searchField]);

    // FILTERING
    const [showFilters, setShowFilters] = React.useState(false);

    React.useEffect(() => {
        // When filters are closed, reset them
        if (!showFilters && filters != undefined) {
            updateParams(filters.map(f => f.id));
        }
        // When filters change, clear selections (as rows might not meet the new filtering criteria)
        setSelectedFromPrevPage([]);
        setSelected([]);
    }, [showFilters]);

    // PAGINATION (zero-based index)
    const [page, setPage] = React.useState(-1);
    const [rowsPerPage, setRowsPerPage] = React.useState(-1);
    const [apiCount, setApiCount] = React.useState(-1);
    const [rowsPerPageOptions, setRowsPerPageOptions] = React.useState([]);

    React.useEffect(() => {
        // Update pagination variables when data changes
        if (data != null) {
            setPage(data.currentPage - 1);
            setRowsPerPage(data.itemsPerPage);
            setApiCount(data.count);
            setRowsPerPageOptions([data.itemsPerPageDefault, data.itemsPerPageDefault*2, data.itemsPerPageDefault*5, data.itemsPerPageDefault*10]);
        }
    }, [data]);

    const handleChangePage = (newPage) => {
        console.log("handleChangePage", newPage);
        // Only update page if next/prev page is available
        if (newPage > page && data.next != null) {
            setPage(newPage);
            addParam({'page': newPage + 1});
        }
        if (newPage < page && data.previous != null) {
            setPage(newPage);
            addParam({'page': newPage + 1});
        }
    };

    const handleChangeRowsPerPage = (event) => {
        console.log('handleChangeRowsPerPage', event.target.value);
        setPage(0);
        setRowsPerPage(parseInt(event.target.value, 10));
        addParam({'page': 1, 'page_size': event.target.value});
    };

    const labelDisplayedRows = ({ from, to, count }) => { return `${from}-${to} de ${count !== -1 ? count : `mais do que ${to}`}`; }


    // TUTORIAL
    const [showTutorial, setShowTutorial] = React.useState(false);

    const goingToStep = (nextIndex) => {
        // When opening a tutotial tooltip, scroll into element
        // (Specially for mobile which has table horizontal scroll)
        if (tutorial && nextIndex < tutorial.length && document.querySelector(tutorial[nextIndex]['element'])) {
            document.querySelector(tutorial[nextIndex]['element']).scrollIntoView({ behavior: 'smooth' });
        }
    }

    // ALETS
    const [alert, setAlert] = React.useState(undefined);

    return (
        <Grid sx={{ width: '100%', display: 'flex', flexWrap: 'wrap' }} container={true}>

            {
                tutorial &&
                <Steps
                    enabled={showTutorial}
                    steps={tutorial}
                    initialStep={0}
                    onExit={() => setShowTutorial(false)}
                    onBeforeChange={goingToStep}
                    options={{
                        nextLabel: 'Próximo',
                        prevLabel: 'Anterior',
                        doneLabel: 'Concluir',
                        hidePrev: true,
                        disableInteraction: true
                    }}
                />
            }

            <Grid
                xs={12}
                sx={{
                    mb: 5,
                }}
            >
                <SelectTableToolbar
                    entityNameSingular={entityNameSingular}
                    loading={loading}
                    numSelected={selected.length}
                    rowCount={data ? data.results.length : 0}
                    potencialCount={data ? data.count : 0}
                    searchField={searchField}
                    allowSearch={allowSearch}
                    setSearchField={setSearchField}
                    filters={filters}
                    showFilters={showFilters}
                    setShowFilters={setShowFilters}
                    docsExport={docsExport}
                    multipleSelectionOps={multipleSelectionOps}
                    params={{ ...params, ids: selected.join(',') }}
                    selected={selected}
                    selectedFromPreviousPage={selectedFromPrevPage}
                    removeSelectedFromPreviousPage={removeSelectedFromPrevPage}
                    addNew={addNew}
                    showTutorial={() => setShowTutorial(true)}
                    hasTutorial={tutorial && tutorial.length > 0}
                    goBack={goBack}
                    onSubmit={onSubmit != undefined ? () => onSubmit(selected) : undefined}
                />
            </Grid>

            <Grid
                xs='12'
                lg={showFilters ? 9 : 12}
                sx={{
                    pr: showFilters ? 5 : 0,
                    order: { xs: 1, lg: 0 }
                }}
            >
                <TableContainer
                    sx={{
                        overflow: showTutorial ? 'visible' : 'auto',
                    }}
                >
                    <Table
                        sx={{
                            minWidth: 750,
                            borderCollapse: 'separate',
                            borderSpacing: '0 16px',
                            pr: '2rem'
                        }}
                        aria-labelledby="tableTitle"
                        size={'medium'}
                    >
                        <SelectTableHeader
                            cols={cols}

                            allowSelect={allowSelect}
                            numSelected={selected.length}
                            selectedFromPrevPage={!!selectedFromPrevPage ? selectedFromPrevPage.length : 0}
                            order={order}
                            orderBy={orderBy}
                            onSelectAllClick={handleSelectAllClick}
                            onRequestSort={handleRequestSort}
                            rowCount={data ? data.results.length : 0}
                        />
                        <TableBody>
                            {
                                data && data.results && data.results.map((row) => {
                                    const isItemSelected = isSelected(row.id);
                                    const labelId = `enhanced-table-checkbox-${row.id}`;

                                    return (
                                        <TableRow
                                            hover={allowSelect}
                                            onClick={(event) => allowSelect && handleClick(event, row.id)}
                                            role="checkbox"
                                            aria-checked={isItemSelected}
                                            tabIndex={-1}
                                            key={row.id}
                                            selected={isItemSelected}
                                            sx={{
                                                backgroundColor: '#FFFFFF',
                                                boxShadow: '0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)'
                                            }}
                                        >
                                            {
                                                getRow(row).map(
                                                    (col, index) =>
                                                        <TableCell
                                                            sx={
                                                                index == (cols.length - 1) ? { ...tableCellStyle, border: 'none' } : tableCellStyle
                                                            }
                                                            align="left"
                                                            component={index == 0 && 'th'}
                                                        >
                                                            {
                                                                loading
                                                                    ?
                                                                    <Skeleton />
                                                                    :
                                                                    col
                                                            }
                                                        </TableCell>
                                                )
                                            }
                                            {
                                                allowSelect &&
                                                <TableCell
                                                    padding="checkbox"
                                                    sx={tableCellStyle}
                                                >
                                                    {
                                                        !loading &&
                                                        <Tooltip title={isItemSelected ? "Desselecionar" : "Selecionar"}>
                                                            <Checkbox
                                                                color="primary"
                                                                checked={isItemSelected}
                                                                inputProps={{
                                                                    'aria-labelledby': labelId,
                                                                }}
                                                                icon={<CircleOutlinedIcon />}
                                                                checkedIcon={<CheckCircleIcon />}
                                                                sx={{
                                                                    right: '-50%',
                                                                    backgroundColor: '#FFFFFF'
                                                                }}
                                                            />
                                                        </Tooltip>
                                                    }
                                                </TableCell>
                                            }
                                        </TableRow>
                                    );
                                })
                            }
                            {
                                !loading && data && data.results.length == 0 &&
                                <TableRow
                                >
                                    <TableCell
                                        colSpan={cols.length + 1}
                                        sx={tableCellStyle}
                                    >
                                        Não foram encontrados resultados.
                                    </TableCell>
                                </TableRow>

                            }
                            {
                                !loading && !data &&
                                <TableRow
                                >
                                    <TableCell
                                        colSpan={cols.length + 1}
                                        sx={tableCellStyle}
                                    >
                                        Ocorreu um erro, por favor tente recarregar a página e se o problema persistir contacte o suporte técnico.
                                    </TableCell>
                                </TableRow>
                            }

                        </TableBody>
                    </Table>
                </TableContainer>
                {
                    !loading && data && data.results.length != 0 && page >= 0 &&
                    <Grid container direction="row" wrap="wrap" className='pagination-wrapper'>
                        <Pagination 
                            count={Math.ceil(apiCount/rowsPerPage)}
                            showFirstButton 
                            showLastButton
                            page={page+1}
                            onChange={(e, newPage) => handleChangePage(newPage-1)}
                            sx={{mr: 'auto', my:'auto'}}
                        />
                        <TablePagination
                            rowsPerPageOptions={rowsPerPageOptions}
                            component="div"
                            count={apiCount}
                            page={page}
                            onPageChange={(e, newPage) => handleChangePage(newPage)}
                            labelDisplayedRows={labelDisplayedRows}
                            
                            rowsPerPage={rowsPerPage}
                            labelRowsPerPage={'Resultados por página:'}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </Grid>
                }
            </Grid>

            {
                filters != undefined && showFilters &&
                <Grid
                    xs='12'
                    lg='3'
                    sx={{
                        backgroundColor: 'white',
                        p: 5,
                        ml: 'auto',
                        order: { xs: 0, lg: 1 },
                        overflowX: 'hidden'
                    }}
                >
                    <Grid sx={{ display: 'flex' }}>
                        <Typography sx={{ padding: 0, margin: 0, mb: 1, mr: 'auto' }}>Filtros</Typography>
                        <Tooltip title="Limpar filtros">
                            <IconButton
                                onClick={() => setShowFilters(false)}
                            >
                                <CloseIcon />
                            </IconButton>
                        </Tooltip>
                    </Grid>
                    <FormGroup
                        fields={filters}
                        updateParams={(to_clean, id, val) => {
                            // When filters change, clear selections (as rows might not meet the new filtering criteria)
                            setSelectedFromPrevPage([]);
                            setSelected([]);
                            // Propagate changes to upper component
                            updateParams(to_clean, id, val);
                        }}
                        initialVals={params}
                        sx={{
                            minWidth: '100%',
                            maxWidth: '100%',
                            mr: 0,
                            my: 1
                        }}
                    />
                </Grid>
            }

            { /* Operations confirmation box */}
            {
                alert &&
                <Alert
                    title={alert.title}
                    text={alert.text}
                    action={alert.action}
                    close={() => setAlert(undefined)}
                />
            }
        </Grid>
    );
}
