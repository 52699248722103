import * as React from 'react';
import PropTypes from 'prop-types';
import {
    FilterList as FilterListIcon,
    Download as FileDownloadIcon,
    Search as SearchIcon,
    AddCircle as AddCircleIcon,
    Help as HelpIcon,
    ArrowBackIos as ArrowBackIosIcon
} from '@mui/icons-material';
import {
    Box,
    Toolbar,
    Typography,
    IconButton,
    Tooltip,
    CircularProgress,
    TextField,
    Menu,
    MenuItem,
    Grid,
    Link,
    Button,
    Skeleton,
    Alert
} from '@material-ui/core';
import DownloadModal from '../DownloadModal';

import {
    buildParams,
    openChaveLusaAdmin
} from '../../../utils';
import { useNavigate, useSearchParams } from 'react-router-dom';

/**
 * Renders table toolbar
 * 
 * @param {Integer} rowCount Number of rows in table
 * @param {Integer} potencialCount Number of rows in API (retrieved in exports)
 * @param {Integer} numSelected Number of rows selected
 * @param {Boolean} loading Is the content loading?
 * @param {String} searchField Text field text
 * @param {method} setSearchField Update search field text
 * @param {Object[]} filters See components.ChaveLusa.FormGroup for structure 
 * @param {Boolean} showFilters Render filters?
 * @param {method} setShowFilters Change showFilters state
 * @param {Object[]} docsExport Defines the exports list, for each the label to display, url to download export, and modal title and description
 *          {label: String, url: String, title: String, description: String}
 * @param {Array} multipleSelectionOps Operations to be performed on multiple selection
 *          {
 *              icon: <Icon:The visual icon that happears in the topbar when multiple elements selected>,              
 *              label: <String: text to show on tooltip when hover icon>
 *              on_click: <Method: Called when user clicks icon, list of ids selected given as parameter>
 *          }
 * @param {Object} params Dictiomary of request params
 * @param {String} addNew Method called when user clicks on button to add new entity to table. Add button is not displayed if null.
 * @param {Boolean} showTutorial Method than when called shows help tutorial
 */
const SelectTableToolbar = ({
    entityNameSingular, rowCount, potencialCount, numSelected, loading, searchField, allowSearch, setSearchField, filters, showFilters, setShowFilters, params, addNew, showTutorial, hasTutorial, goBack, onSubmit,
    selectedFromPreviousPage, removeSelectedFromPreviousPage,
    // Bulk operations
    selected, docsExport, multipleSelectionOps
}) => {

    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    // Op param trigger 
    React.useEffect(() => {
        if (searchParams.get("op")==='new') {
            addNew(); 
            navigate("?"); // Reset params
        }
    }, [searchParams])

    // Export modal
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [downloadExport, setDownloadExport] = React.useState(null);
    const open = Boolean(anchorEl);
    const openExports = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const closeExports = (exp) => {
        setAnchorEl(null);
        if (exp) {
            setDownloadExport(exp);
        }
    };

    return (
        <Toolbar
            sx={{
                pl: { xs: 0, sm: 0 },
                pr: { xs: 0, sm: 0 },
                display: 'flex',
                flexDirection: 'column'
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    flexWrap: 'wrap',
                    width: '100%'
                }}
            >

                <Grid xs='12' md='auto' sx={{ display: 'flex', mr: 'auto' }}>

                    {numSelected > 0 ? (
                        <Typography
                            color="inherit"
                            variant="subtitle1"
                            component="div"
                        >
                            {numSelected} {entityNameSingular ? entityNameSingular.toLowerCase().replaceAll('ão', 'õe') : 'entidade'}(s) selecionado(s)
                        </Typography>
                    ) : (
                        <Typography
                            variant="h6"
                            id="tableTitle"
                            component="div"
                        >
                            {
                                goBack !== undefined &&
                                <Tooltip title="Voltar">
                                    <Link
                                        onClick={goBack}
                                        sx={{ cursor: 'pointer' }}
                                        underline="hover"
                                    >
                                        <ArrowBackIosIcon sx={{ fontSize: 'small' }} />
                                    </Link>
                                </Tooltip>
                            }
                            {
                                entityNameSingular
                                    ? entityNameSingular.charAt(0).toUpperCase() + entityNameSingular.slice(1).toLowerCase().replaceAll('ão', 'õe') + 's'
                                    : 'Tabela sem nome'

                            }
                        </Typography>
                    )}

                    {
                        loading &&
                        <CircularProgress sx={{ mr: 'auto', ml: 2 }} />
                    }
                </Grid>

                <Grid sm='12' md='auto' sx={{ display: 'flex', ml: 'auto', flexWrap: 'wrap' }}>

                    {
                        allowSearch && !numSelected &&
                        <Grid xs='12' sm='auto' sx={{ display: 'flex', ml: 'auto' }} className="table-search">
                            <SearchIcon
                                sx={{ mr: 1, my: 'auto', ml: 'auto', color: 'secondary.main' }}
                            />
                            <TextField
                                label="Pesquisar"
                                variant="standard"
                                value={searchField}
                                onChange={(event) => setSearchField(event.target.value)}
                            />
                        </Grid>
                    }

                    <Grid sx={{ display: 'flex', ml: { xs: 'auto', sm: 0 } }} className="table-search">

                        {
                            !numSelected && filters !== undefined &&
                            <Tooltip title={filters === null ? "A carregar filtros..." : showFilters ? "Limpar filtros" : "Filtrar"} className='table-filter'>
                                {
                                    filters === null
                                        ?
                                        <Skeleton width={36} />
                                        :
                                        <IconButton
                                            onClick={() => setShowFilters(!showFilters)}
                                        >
                                            <FilterListIcon />
                                        </IconButton>
                                }
                            </Tooltip>
                        }

                        {
                            numSelected > 0 && multipleSelectionOps && multipleSelectionOps.length > 0 &&
                            multipleSelectionOps.map(ms =>
                                <Tooltip
                                    title={ms.label}
                                >
                                    <IconButton
                                        onClick={() => ms.onClick(selected)}
                                    >
                                        {ms.icon}
                                    </IconButton>
                                </Tooltip>
                            )
                        }

                        {
                            rowCount > 0 && docsExport && docsExport.length > 0 &&
                            <>
                                <Tooltip
                                    className='table-export'
                                    title={
                                        numSelected
                                            ? "Exportar selecionados"
                                            :
                                            potencialCount > 0
                                                ? `Exportar todos (${potencialCount})`
                                                : 'Exportar todos'
                                    }
                                    id="export-button"
                                    onClick={openExports}
                                >
                                    <IconButton>
                                        <FileDownloadIcon />
                                    </IconButton>
                                </Tooltip>
                                <Menu
                                    id="basic-menu"
                                    anchorEl={anchorEl}
                                    open={open}
                                    onClose={() => closeExports(null)}
                                    MenuListProps={{
                                        'aria-labelledby': 'export-button',
                                    }}
                                >
                                    {docsExport.map((e) => <MenuItem onClick={() => closeExports(e)}>{e.label}</MenuItem>)}
                                </Menu>
                                <DownloadModal
                                    open={downloadExport != null}
                                    handleClose={() => setDownloadExport(null)}
                                    url={downloadExport && downloadExport.url + buildParams(params, ['page'])}
                                    title={downloadExport && downloadExport.title}
                                    description={downloadExport && downloadExport.description}
                                />
                            </>
                        }
                        {
                            !numSelected && addNew &&
                            <Tooltip title="Adicionar novo" className='table-add-new'>
                                <IconButton
                                    onClick={() => addNew()}
                                >
                                    <AddCircleIcon />
                                </IconButton>
                            </Tooltip>
                        }
                        {
                            hasTutorial && !numSelected &&
                            <Tooltip title="Ajuda">
                                <IconButton
                                    onClick={showTutorial}
                                >
                                    <HelpIcon />
                                </IconButton>
                            </Tooltip>
                        }
                        {
                            numSelected > 0 && onSubmit != undefined &&
                            <Button color="primary" variant="contained" onClick={onSubmit}>Adicionar</Button>
                        }
                    </Grid>
                </Grid>
            </Box>
            {
                selectedFromPreviousPage && selectedFromPreviousPage.length > 0 &&
                <Alert
                    severity='info'
                    action={<Button color="inherit" size="small" onClick={removeSelectedFromPreviousPage}>Limpar seleção</Button>}
                    sx={{mt: 1, width: '100%'}}
                >
                    Mantém-se a seleção do(s) {entityNameSingular ? entityNameSingular.toLowerCase().replaceAll('ão', 'õe') : 'entidade'}(s) das páginas anteriores: {selectedFromPreviousPage.join('; ')}
                </Alert>
            }
        </Toolbar >
    );
};

SelectTableToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired,
};

export default SelectTableToolbar;