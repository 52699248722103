import React, { useState, useEffect } from "react";

import {
    Grid,
    Typography,
    Tooltip
} from '@mui/material';

import {
    Home as HomeIcon,
    LocationOn as LocationOnIcon,
    AttachFile as AttachFileIcon,
    SquareFoot as SquareFootIcon,
    Roofing as RoofingIcon,
    KingBed as KingBedIcon,
    Shower as ShowerIcon,
    Garage as GarageIcon
} from '@mui/icons-material';

import {
    JSExtender
} from '../../../../utils';

/**
 * @param {Component} (Optional) actions React component with its own logic
 * @param {Component} (Optional) insideElements React component with its own logic
 */
const PropertyCardHorizontal = ({ property, my, click, actions, insideElements }) => {

    console.log("COMPONENT PropertyCardHorizontal", property);

    const [selected, setSelected] = useState(false);
    const close = () => { };
    const open = (event) => {
        // If click os not on a non clickable area, propagate it to upper component
        if (!JSExtender.hasSomeParentTheClass(event.target, 'dont-hover'))
            click();
    };

    return (
        <Grid
            container
            direction="row"
            className={!selected ? "elementSelectable" : "elementSelectable selected"}
            my={my}
        >
            {
                property.images && property.images.length>0 &&
                <Grid
                    xs={4}
                    m={0}
                    sx={{
                        backgroundImage: 'url(' + process.env.REACT_APP_STATIC + property.images[0].image + ')',
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center',
                    }}
                    className="boxImageTriangle"
                    onClick={open}
                >
                </Grid>
            }
            <Grid
                justifyContent="center"
                container
                direction="column"
                p={3}
                xs={8}
            >
                <Grid onClick={open}>
                    {/** Title */}
                    <Typography variant="h6" mb={0}>{property.title}</Typography>

                    {/** Details 1 */}
                    <Grid container direction="row">
                        <Typography variant="caption" mr={2}>
                            <Tooltip title="Referência">
                                <HomeIcon color="secondary" fontSize="inherit" sx={{ mr: 1, verticalAlign: 'middle' }} />
                            </Tooltip>
                            {property.id}
                        </Typography>
                        {
                            property.parish && 
                            <Typography variant="caption" mr={2}>
                                <Tooltip title="Concelho">
                                    <LocationOnIcon color="secondary" fontSize="inherit" sx={{ mr: 1, verticalAlign: 'middle' }} />
                                </Tooltip>
                                {property.parish.county.name}
                            </Typography>
                        }
                        <Typography variant="caption" mr={2}>
                            <Tooltip title="Estado">
                                <AttachFileIcon color="secondary" fontSize="inherit" sx={{ mr: 1, verticalAlign: 'middle' }} />
                            </Tooltip>
                            {property.state}
                        </Typography>
                    </Grid>

                    {/** Details 2 */}
                    <Grid container direction="row">
                        <Typography variant="caption" mr={2}>
                            <Tooltip title="Área do lote">
                                <SquareFootIcon color="secondary" fontSize="inherit" sx={{ mr: 1, verticalAlign: 'middle' }} />
                            </Tooltip>
                            {property.area}
                        </Typography>
                        <Typography variant="caption" mr={2}>
                            <Tooltip title="Área útil">
                                <RoofingIcon color="secondary" fontSize="inherit" sx={{ mr: 1, verticalAlign: 'middle' }} />
                            </Tooltip>
                            {property.areaconstructed}
                        </Typography>
                        <Typography variant="caption" mr={2}>
                            <Tooltip title="Quartos">
                                <KingBedIcon color="secondary" fontSize="inherit" sx={{ mr: 1, verticalAlign: 'middle' }} />
                            </Tooltip>
                            {property.bedrooms}
                        </Typography>
                        <Typography variant="caption" mr={2}>
                            <Tooltip title="Casas de banho">
                                <ShowerIcon color="secondary" fontSize="inherit" sx={{ mr: 1, verticalAlign: 'middle' }} />
                            </Tooltip>
                            {property.bathrooms}
                        </Typography>
                        { 
                            property.garage &&
                            <Typography variant="caption" mr={2}>
                                <Tooltip title="Garagem">
                                    <GarageIcon color="secondary" fontSize="inherit" sx={{ mr: 1, verticalAlign: 'middle' }} />
                                </Tooltip>
                            </Typography>
                        }
                    </Grid>


                    {/** Price */}
                    <Typography sx={{fontWeight: 'bold'}} color='primary' variant='h6'>
                        {property.price.toLocaleString()}€
                        {property.negociable ? ' (Negociável)' : ''}
                        {property.priceHidden ? '(Sob consulta)' : ''}
                    </Typography>
                </Grid>

                {
                    actions && <Grid className="dont-hover">{actions}</Grid>
                }
            </Grid>
            {
                insideElements && <Grid className="dont-hover" xs={12}>{insideElements}</Grid>
            }
        </Grid>
    );
}

export default PropertyCardHorizontal;