import React, { useState, useEffect } from "react";

import {
    Grid,
    Typography,
    Chip
} from '@mui/material';

import ReactPlayer from "react-player";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { keyValue } from "../utils";

const PropertyAdvert = ({ property }) => {

    return (
        <Grid
            container
            direction="column"
            className="elementSelectable disabled"
            p={3}
            my={3}
        >
            <Grid mb={3}>
                <Typography variant="h6">Informações gerais</Typography>
                {property.type && keyValue({ key: "Tipo", value: property.type.name })}
                {property.conservation && keyValue({ key: "Estado de conservação", value: property.conservation_display })}
                {!!property.constructionYear && keyValue({ key: "Ano de construção", value: property.constructionYear })}
                {property.energyclass && keyValue({
                    key: "Classe energética",
                    value: property.energyclass_display
                        + (property.energyCertificateNumber ? ' (' + property.energyCertificateNumber + ')' : '')
                })}
            </Grid>
            <Grid mb={3}>
                <Typography variant="h6">Anúncio</Typography>
                {keyValue({ key: "Propridade exclusiva", value: property.exclusive ? 'Sim' : 'Não' })}
                {keyValue({ key: "Preço sob consulta", value: property.priceHidden ? 'Sim' : 'Não' })}
                {keyValue({ key: "Imóvel da banca", value: property.bankproperty ? 'Sim' : 'Não' })}
                {keyValue({ key: "Aceita permuta", value: property.negociable ? 'Sim' : 'Não' })}
                {keyValue({ key: "Preço negociável", value: property.exchange ? 'Sim' : 'Não' })}
                {property.state && keyValue({ key: "Finalidade", value: property.state_display })}
                {keyValue({
                    key: "Preço",
                    value: property.price.toLocaleString() + '€'
                        + (property.negociable ? ' (Negociável)' : '')
                        + (property.priceHidden ? '(Sob consulta)' : '')
                })}
                {keyValue({ key: "Descrição", value: property.description, html: true })}
            </Grid>
            {
                property.house &&
                <Grid mb={3}>
                    <Typography variant="h6">Moradias e apartamentos</Typography>
                    {property.house.usageLicense && keyValue({ key: "Licença de utilização", value: property.house.usageLicense })}
                    {property.house.studentAccommodation && keyValue({ key: "Informação adicional", value: "Aceita estudantes" })}
                    {property.house.affordableRents && keyValue({ key: "Informação adicional", value: "Renda acessível" })}
                </Grid>
            }
            {
                property.lot &&
                <Grid mb={3}>
                    <Typography variant="h6">Terrenos</Typography>
                    {property.lot.purpose && keyValue({ key: "Propósito", value: property.lot.purpose_display })}
                    {property.lot.landType && keyValue({ key: "Tipo de terreno", value: property.lot.landType_display })}
                    {property.lot.license && keyValue({ key: "Licença de construção", value: property.lot.license_display })}
                    {property.lot.slope && keyValue({ key: "Inclinação", value: property.lot.slope_display })}
                </Grid>
            }
            {
                property.store &&
                <Grid mb={3}>
                    <Typography variant="h6">Lojas</Typography>
                    {property.store.divisions && keyValue({ key: "Divisões", value: property.store.divisions })}
                    {property.store.type && keyValue({ key: "Tipo", value: property.store.type_display })}
                    {property.store.floors && keyValue({ key: "N.º andares", value: property.store.floors })}
                </Grid>
            }
            {
                property.building &&
                <Grid mb={3}>
                    <Typography variant="h6">Edifícios</Typography>
                    {property.building.feature && keyValue({ key: "Tipo", value: property.building.feature_display })}
                    {property.building.floors && keyValue({ key: "N.º andares", value: property.building.floors })}
                    {property.building.lifts && keyValue({ key: "N.º elevadores", value: property.building.lifts })}
                    {property.building.parkingSlots && keyValue({ key: "N.º lugares estacionamento", value: property.building.parkingSlots })}
                </Grid>
            }
            <Grid mb={3}>
                <Typography variant="h6">Números</Typography>
                {keyValue({ key: "Quartos", value: property.bedrooms })}
                {keyValue({ key: "Casas de banho", value: property.bathrooms })}
                {keyValue({ key: "Área total", value: property.area, notes: "Bruta, ou do lote" })}
                {keyValue({ key: "Área do terreno", value: property.areaground })}
                {keyValue({ key: "Área útil", value: property.areaconstructed, notes: "Interior do imóvel" })}
            </Grid>
            {
                property.feature && property.feature.length > 0 &&
                <Grid mb={3}>
                    <Typography variant="h6">Características</Typography>
                    <Grid container>
                        {
                            property.feature.map(
                                f =>
                                    <Chip icon={f.icon && <FontAwesomeIcon icon={f.icon.replace("fa-", "").split(" ")} />} label={f.name} color="primary" variant="outlined" />
                            )
                        }
                    </Grid>
                </Grid>
            }
            {
                property.video && 
                <Grid mb={3}>
                    <Typography variant="h6">Vídeo</Typography>
                    <ReactPlayer
                        url={property.video.url}
                        width='100%'
                        controls={true}
                    />
                </Grid>
            }
        </Grid>
    );
}

export default PropertyAdvert;