import {
    Button,
    Tooltip,
    Typography
} from '@material-ui/core';

import {
    Event as EventIcon,
    Chat as ChatIcon,
    AssignmentLate as AssignmentLateIcon,
    Check as CheckIcon,
    Person as PersonIcon,
    SupervisorAccount as SupervisorAccountIcon,
    Archive as ArchiveIcon,
    FormatListNumbered as FormatListNumberedIcon,
    Email as EmailIcon,
    StickyNote2 as StickyNote2Icon,
    Phone as PhoneIcon,
    SyncLock as SyncLockIcon
} from '@mui/icons-material';

import {
    getDictsLabelId,
    buildFilterSelect,
    buildFilterSlider,
    buildFilterSwitch,
    buildFilterText,
    validatorIsInteger
} from '../../../Components/Chavelusa/FormGroup/utils';

import {
    is_agent,
    canReadSensitiveData
} from '../../../../Services/permissions';

import {
    reduceString
} from '../../../utils';


/**
 * This variable defines the cols of the clients table
 */
export const getCols = (duplicates) => {
    const cols_prepare = [
        {
            id: 'created',
            label: 'Adicionado',
            sort: true
        },
        {
            id: duplicates ? 'name' : 'client__name',
            label: 'Nome',
            sort: true
        }
    ];
    if (!duplicates) {
        if (!is_agent) {
            cols_prepare.push({
                id: 'agent',
                label: 'Agente',
                sort: false
            });
        }
        cols_prepare.push({
            id: 'state',
            label: 'Estado',
            sort: false
        });
        cols_prepare.push({
            id: 'operations',
            label: 'Ficha de cliente',
            sort: false
        });
        cols_prepare.push({
            id: 'notes',
            label: 'Notas',
            sort: false
        });
    } else {
        cols_prepare.push({
            id: 'phone',
            label: 'Contacto principal',
            sort: false
        });
        cols_prepare.push({
            id: 'phone2',
            label: 'Contact secundário',
            sort: false
        });
        cols_prepare.push({
            id: 'email',
            label: 'Email',
            sort: false
        });
        cols_prepare.push({
            id: 'email2',
            label: 'Email secundário',
            sort: false
        });
    }
    return cols_prepare;
}

/**
 * This method returns an array of react components, one for each column of the table
 * @param {Object} obj A client object fom the list retrieved from the /client_agents API endpoint
 * @returns {Component[]} One component per column of the table for the client row
 */
export const getRow = (obj, duplicates) => {
    let row = [];
    // Adicionado
    row.push(
        <>
            <EventIcon color="secondary" fontSize="inherit" sx={{ mr: 1, verticalAlign: 'middle' }} />
            {new Date(obj.created).toLocaleDateString('pt-PT')}
        </>
    );
    // Nome
    row.push(
        <>
            <PersonIcon color="secondary" fontSize="inherit" sx={{ mr: 1, verticalAlign: 'middle' }} />
            {duplicates ? obj.name : obj.client.name}
        </>
    );
    if (!duplicates) {
        // Agente
        if (!is_agent) {
            row.push(
                <>
                    <SupervisorAccountIcon color="secondary" fontSize="inherit" sx={{ mr: 1, verticalAlign: 'middle' }} />
                    {obj.agent.name}
                </>
            );
        }
        // Estado
        if (obj.replaced_by) {
            row.push(<Tooltip title="Cliente transferido para outro agente"><SyncLockIcon color="secondary" /></Tooltip>);
        } else if (obj.archived) {
            row.push(<Tooltip title="Cliente arquivado"><ArchiveIcon color="secondary" /></Tooltip>);
        } else if (obj.classified && !obj.forms_pending) {
            row.push(
                <>
                    <Tooltip title="Cliente sem operações pendentes"><CheckIcon color="primary" /></Tooltip>
                    {obj.can_be_archived && <Tooltip title="Cliente passível de ser arquivado"><ArchiveIcon color="warning" /></Tooltip>}
                </>
            );
        } else {
            row.push(
                <>
                    {!obj.classified && <Tooltip title="Qualificação pendente"><AssignmentLateIcon color="warning" /></Tooltip>}
                    {obj.forms_pending && <Tooltip title="Formulário de contacto pendente"><ChatIcon color="warning" /></Tooltip>}
                    {obj.can_be_archived && <Tooltip title="Cliente passível de ser arquivado"><ArchiveIcon color="warning" /></Tooltip>}
                </>
            );
        }
    } else {
        // Contacts and emails
        row.push(
            <>
                <PhoneIcon color="secondary" fontSize="inherit" sx={{ mr: 1, verticalAlign: 'middle' }} />
                {obj.phone}
            </>
        );
        row.push(
            <>
                {
                    obj.phone2 && 
                    <>
                        <PhoneIcon color="secondary" fontSize="inherit" sx={{ mr: 1, verticalAlign: 'middle' }} />
                        {obj.phone2}
                    </>
                }
            </>
        );
        row.push(
            <>
                <EmailIcon color="secondary" fontSize="inherit" sx={{ mr: 1, verticalAlign: 'middle' }} />
                {obj.email}
            </>
        );
        row.push(
            <>
                {
                    obj.email2 && 
                    <>
                        <EmailIcon color="secondary" fontSize="inherit" sx={{ mr: 1, verticalAlign: 'middle' }} />
                        {obj.email2}
                    </>
                }
            </>
        );
    }
    // Operações
    row.push(
        <a href={process.env.REACT_APP_ADMIN_URL + '/clientes/' + obj.id + '/futuro'}>
            <Button 
                href={(duplicates ? '/clientes/duplicados/' : '/clientes/') + obj.id} 
                variant="outlined"
            >
                {duplicates ? 'Desbloquear' : 'Aceder'}
            </Button>
        </a>
    );
    if (!duplicates) {
        // Notas
        row.push(
            <Tooltip title={<div style={{ whiteSpace: 'pre-line' }}>{obj.client.notes ? obj.client.notes : ''}</div>}>
                <Typography>
                    {
                        obj.client.notes &&
                        <StickyNote2Icon color="secondary" fontSize="inherit" sx={{ mr: 1, verticalAlign: 'middle' }} />
                    }
                    {reduceString(obj.client.notes, 30)}
                </Typography>
            </Tooltip>
        );
    }
    return row;
}

/**
 * Builds the clients table filters, given data retrieved from the client_agents/filters API endpoint
 * @param {Object} data The object returned from the API
 * @returns {Object[]} filters An aray of objects that define the filters fields
 */
export const buildFilters = (data) => {
    let filters = [];
    var type_classified_dependency = [{ id: 'type', values: ['classified', 'buyer', 'renter'] }];

    filters.push(
        buildFilterSwitch('Arquivado', 'archived', false, null)
    );

    if (!is_agent) {
        filters.push(
            buildFilterSwitch('Excluir clientes migrados entre agentes', 'exclude_migrated', true, null)
        );
    }

    if (!is_agent && 'agents' in data) {
        filters.push(
            buildFilterSelect('Agente', 'select', 'agent', getDictsLabelId(data['agents'], 'name', 'id'), null, false, null, undefined, true)
        );
    }

    let types = [
        { label: 'Qualificado como...', group: true },
        { label: 'Proprietário', id: 'owner' },
        { label: 'Comprador', id: 'buyer' },
        { label: 'Arrendatário', id: 'renter' },
        { label: 'Estado', group: true },
        { label: 'Qualificado', id: 'classified' },
        { label: 'Não qualificado', id: 'unclassified' },
        { label: 'Formulários pendentes', id: 'forms_pending' },
        { label: 'Passível de ser arquivado', id: 'can_be_archived' }
    ]

    if (!is_agent && canReadSensitiveData) {
        types.push({ label: 'Transferido entre agentes', id: 'transferred_between_agents' });
    }

    filters.push(
        buildFilterSelect(
            'Tipo de Cliente', 'select', 'type',
            types,
            null, null
        )
    );

    filters.push(
        buildFilterSelect(
            'Estado da qualificação',
            'select',
            'classifications_closed',
            [
                { label: 'Aberta', id: 'open' },
                { label: 'Fechada', id: 'closed' }
            ],
            type_classified_dependency
        )
    );

    if ('counties' in data) {
        filters.push(
            buildFilterSelect(
                'Concelho', 'select', 'classifications__locations__county',
                getDictsLabelId(data['counties'], 'name', 'id'),
                type_classified_dependency,
                false, null, undefined, true
            )
        );
    }

    if ('property_types' in data) {
        filters.push(
            buildFilterSelect(
                'Tipo de propriedade', 'select', 'classifications__propertyType',
                getDictsLabelId(data['property_types'], 'name', 'id'),
                type_classified_dependency
            )
        );
    }
    if ('features' in data) {
        filters.push(
            buildFilterSelect(
                'Características', 'multipleselect', 'features',
                getDictsLabelId(data['features'], 'name', 'id'),
                type_classified_dependency,
                false, null, undefined, true
            )
        );
    }
    if ('properties' in data) {
        filters.push(
            buildFilterSelect(
                'Propriedade associada', 'select', 'property',
                getDictsLabelId(data['properties'], 'id', 'id'),
                type_classified_dependency,
                false, null, undefined, true
            )
        );
    }
    filters.push(
        buildFilterSelect(
            'Financiamento', 'select', 'classifications__financing',
            [{ label: 'Com financiamento', id: 'true' }, { label: 'Sem financiamento', id: 'false' }],
            type_classified_dependency
        )
    );
    filters.push(
        buildFilterSelect(
            'Aceita trespasse', 'select', 'classifications__transfer',
            [{ label: 'Aceita', id: 'true' }, { label: 'Não aceita', id: 'false' }],
            type_classified_dependency
        )
    );

    if ('bedrooms_min' in data && 'bedrooms_max' in data) {
        filters.push(
            buildFilterSlider(
                'Quartos', 'classifications__bedrooms__gte',
                data['bedrooms_min'], data['bedrooms_max'],
                type_classified_dependency
            )
        );
    }

    if ('investment_min' in data && 'investment_max' in data) {
        filters.push({
            ...buildFilterText(
                `Investimento mínimo (entre ${data['investment_min']} e ${data['investment_max']})`, 
                'classifications__investment__gte',
                false,
                (v) => validatorIsInteger(v) && parseInt(v)>=data['investment_min'] && parseInt(v)<=data['investment_max'],
                undefined,
                'number'
            ),
            dependency: type_classified_dependency
        });
    }

    return filters;
}

/**
 * This methos builds the tutorial object
 */
const buildTutorial_partial = [
    {
        element: '.client_agents_list table',
        intro: is_agent ? 'Nesta página tem acesso à lista dos seus clientes.' : 'Nesta página tem acesso à lista dos clientes.'
    },
    {
        element: '.client_agents_list table thead th:first-of-type',
        intro: 'Ao carregar nas colunas "Adicionado" ou "Nome", pode ordenar por esses atributos, invertendo a ordenação ao segundo clique.'
    },
    {
        element: '.client_agents_list table tbody tr:first-of-type',
        intro: 'Ao clicar num cliente irá selecioná-lo, permitindo a relização de operações conjuntas sobre todos os selecionados.'
    },
    {
        element: `.client_agents_list table tbody tr:first-of-type td:nth-of-type(${is_agent ? 2 : 3})`,
        intro: 'O estado do cliente é apresentado através de símbolos, podendo consultar o seu significado ao passar o rato por cima.'
    },
    {
        element: `.client_agents_list table tbody tr:first-of-type td:nth-of-type(${is_agent ? 3 : 4})`,
        intro: 'Para aceder à ficha de cliente basta carregar neste botão.'
    },
    {
        element: '.client_agents_list .pagination-wrapper',
        intro: 'A navegação entre páginas é feita na parte inferior.'
    },
    {
        element: '.client_agents_list .table-search',
        intro: 'Pode utilizar este campo para pesquisar pelo nome, email ou contacto.'
    },

    {
        element: '.client_agents_list .table-filter',
        intro: 'Pode filtrar a tabela de acordo com as características que procura.'
    },
    {
        element: '.client_agents_list .table-export',
        intro: 'Pode ainda exportar os dados dos seus clientes para uma folha de cálculo.'
    },
    {
        element: '.client_agents_list .table-add-new',
        intro: 'Por fim, clique no botão + para adicionar um novo cliente.'
    }
]

export const buildTutorial = buildTutorial_partial;

/**
 * Builds the export objects for SelectTable
 * @param {String} baseUrl 
 */
export const generate_exports = (baseUrl) => {
    return [
        {
            label: <><FormatListNumberedIcon sx={{ mr: 1 }} /> Lista</>,
            url: baseUrl + 'export/list/',
            title: 'lista de clientes',
            description: 'Esta lista contém os dados gerais de cada cliente. Pode ser usada para arquivo.'
        },
        {
            label: <><EmailIcon sx={{ mr: 1 }} /> Contactos</>,
            url: baseUrl + 'export/email/',
            title: 'contactos',
            description: 'Esta exportação gera um ficheiro VCF, compatível com o serviço de email, onde pode ser importado (Contactos>Importar).<br/><br/>Na importação deve preencher o formulário da seguinte maneira:<br/><ul><li><b>Importar do ficheiro</b>: Selecionar ficheiro .vcf transferido do CRM</li><li><b>Importar atribuições de grupo</b>: Todos (criar grupos se necessário)</li><li><b>Substituir todo o livro de endereços</b>: NÃO SELECIONADO</ul>'
        }
    ]
}

/**
 * Operations for multiple selection
 */
export const multiple_selection_ops = [
    {
        icon: <EmailIcon sx={{ mr: 1 }} />,
        label: "Enviar mensagem",
        onClick: () => {}
    }
]